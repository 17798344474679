.MuiDataGrid-virtualScroller
    background-color: transparent !important
    // border-radius: 25px !important

.MuiDataGrid-columnHeaders
    background-color: #F4F2FF !important
    box-shadow: 0px 1px 3px #cdcdcd
    border-bottom: none

.MuiDataGrid-columnHeaderTitleContainer
    height: 100%
    color: var(--background-color)
    width: 5px

.MuiDataGrid-root 
    border: none
    box-shadow: 0 0 4px #cdcdcd
    border-radius: 3px
        
// .css-17jjc08-MuiDataGrid-footerContainer
//     border-top: none
//     box-shadow: 1px -2px 3px #f1f1f1