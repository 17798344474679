.create-process-page-section
    width: 80%
    height: 100%
    margin: 0 auto
    .create-process-page-stepper-container
        height: 3em 
        display: flex
        align-items: center
        width: 60%
        margin: 0 auto

.create-process-form
    width: 100%
    margin: 25px auto 0
    display: flex
    flex-direction : column
    gap: 15px
    align-items: center
    &.detailed-form
        flex-direction: row
        justify-content: space-between
        .create-process-form-left-side,.create-process-form-right-side
            width: 45%
            .create-process-form-row
                width: 100%
    // &.upload-media
    //     flex-direction : column
    .create-process-form-row
        width: 50%
        display: flex
        justify-content: space-between

.process-select-box-container
    width: 270px !important
    font-size: 12px !important

.choose-artist-list-container
    width: 100%
    height: calc(100vh - 330px)
    .choose-artist-list-header
        width: 100%
        height: 33px
        display: flex
        align-items: center
        justify-content: space-between
        label
            width: calc(100% - 35px)
        .artist-list-container
            width: 33px
            height: 33px
            background-color: transparent
            position: relative
            .artist-list-drop-down-container
                width: 420px
                height: 250px
                background-color: white
                position: absolute
                top: 35px
                right: 5px
                border-radius: 6px
                padding: 15px 0
                z-index: 2
                box-shadow: 0px 1px 10px #cdcdcd
                overflow: hidden
    .chosen-artist-list-container
        width: calc( 100% - 30px )
        background-color: #f5f5f5
        border-radius: 5px
        height: calc(100vh - 400px)
        margin-top: 10px
        padding: 15px
        p
            width: 100%
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            font-size: 12px
            color: #696969
            text-align: center
            
    .artist-info-card
        width: calc(100% - 30px)
        height: 70px
        display: flex
        align-items: center
        justify-content: space-between
        column-gap: 10px
        background-color: white
        border-radius: 5px
        padding: 0 15px
        margin-bottom: 8px
        .artist-profile-image
            img
                width: 45px
                height: 45px
                border-radius: 50%
        .artist-info-right-side
            width: calc(100% - 55px)
            display: flex
            justify-content: space-between
            label
                width: calc(100% - 120px)
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
            button
                min-width: 65px
                height: 33px
                border-radius: 3px
                background-color: #111831
                color: #f5f5f5
                justify-content: center
                &:disabled
                    background-color: #f5f5f5
                    color: #cdcdcd

.process-upload-image-list-container
    width: 100%
    .process-upload-image-container
        // width: 100%
        height: 55px
        display: flex
        justify-content: space-between
        align-items: center
        column-gap: 5px
        background-color: #f5f5f5
        padding: 0 10px
        border-radius: 5px
        img
            width: 50px
        .process-upload-image-caption
            width: calc(100% - 130px)
            input
                border:none
                outline:none
                width: 100%
                height: 45px
                font-size: 14px
                color: #696969
                background-color: transparent
                font-family: 'Inter', sans-serif
        .process-upload-image-action-container
            width: 40px
            display: flex
            justify-content: space-between
            
            .edit-caption
                width: 33px
                height: 33px
                border-radius: 5px
                background-color: #ECECFB
                color: #353459
            .delete-image
                width: 33px
                height: 33px
                border-radius: 5px
                background-color: #FFE9F2
                color: #CC3F76