.react-calendar {
    width: fit-content !important;
    border: none !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 18px 5px;
    background-color: #374151 !important;
  }
  
  .react-calendar__month-view__weekdays {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0;
    width: 40px !important;
  }
  
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
  }
  .react-calendar__month-view__weekdays__weekday > abbr {
    color: rgb(156, 163, 175, 0.5) !important;
    text-decoration: none !important;
  }
  
  .react-calendar__navigation {
    margin: 0 !important;
  }
  
  .react-calendar__navigation > button {
    color: white;
  }
  
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    border-radius: 10px;
  }
  .react-calendar__navigation__label:hover,
  .react-calendar__navigation__label:focus,
  .react-calendar__navigation__arrow:focus,
  .react-calendar__navigation__arrow:hover {
    background-color: rgb(75, 85, 99, 0.8) !important;
  }
  
  .react-calendar__tile--now {
    background-color: mediumslateblue;
  }
  
  .react-calendar__navigation__label:focus,
  .react-calendar__navigation__arrow:focus {
    border: 1px solid whitesmoke;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    text-align: center !important;
    border-radius: 10px;
    color: whitesmoke;
    padding: 1rem 1.2rem !important;
  }
  
  /* .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: rgb(75, 85, 99, 0.8) !important;
  } */
  
  .react-calendar__year-view__months {
    display: grid !important;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
  .react-calendar__month-view__days > button {
    color: white;
    font: "IBM Plex Mono" 800 14px;
    border-radius: 10px;
    width: 40px !important;
    justify-content: center;
  }
  
  /* .react-calendar__tile:hover {
    background-color: rgb(75, 85, 99, 0.8) !important;
  } */
  
  .react-calendar__tile:disabled,
  .react-calendar__navigation button:disabled {
    color: #696969 !important;
    background-color: transparent !important;
  }
  .react-calendar__tile--active,
  .react-calendar__title:focus {
    background-color: #006edc;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #696969 !important;
  }
  