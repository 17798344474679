.artist_profile
    width:35px
    height: 35px
    border-radius: 50%

.create-artist-page-section
    width: 100%
    height: 100%
    overflow: hidden
    .create-artist-page-body
        margin: 1.5em 0 8px 2.5em
        display: flex
        column-gap: 25px
        .create-artist-page-left-side 
        .create-artist-page-right-side
            width: 60%
            margin-right: auto
            display: flex
            flex-direction: column
            // grid-template-row: repeat(5, 1fr)
            .create-artist-form-row
                display: flex
                justify-content: space-between
                column-gap: 15px

.choose-artist-type-select-box
    width: 460px
    font-size: 14px
.choose-category-select-box
    width: 270px
    font-size: 14px
.choose-product-select-box
    width: 255px
    font-size: 14px

