.ada-admin-dashboard-page
    width: 100vw
    height: 100vh
    overflow: hidden
    .ada-admin-dashboard-body
        display: flex
        height: calc(100% - 3em)
        overflow: auto
        .ada-admin-dashboard-data-container

.form-input-field-container
    display: flex
    justify-content: space-between
    height: 45px
    align-items: center
    margin-bottom: 25px
    column-gap: 25px
    &.vertical-style
        flex-direction: column
        align-items: initial
        height: fit-content
    &.input-text-area
        height: fit-content
    label
        font-size: 11px
    .error-message
        color: brown
        font-size: 14px
        position: absolute
        top: 38px
        left: 3px
        &.vertical-layout
            position: relative
            inset: initial
    .input-text-field,.form-input-password-container,.input-field-textarea,.input-field-selectbox
        // height: 35px
        // border: 1px solid #cdcdcd
        // border-radius: 5px
        // background-color: #fafafa
        // outline:none
        // padding-left: 15px
        // transition: rgb(64, 68, 79) 300ms cubic-bezier(0.4, 0, 1, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 1, 1) 0ms
        font-style: inherit
        font-variant: inherit
        font-weight: inherit
        font-stretch: inherit
        font-family: inherit
        letter-spacing: inherit
        color: currentcolor
        border: 1px solid rgb(208, 213, 221)
        box-sizing: content-box
        background: none
        margin: 0px
        -webkit-tap-highlight-color: transparent
        min-width: 0px
        width: 100%
        animation-name: mui-auto-fill-cancel
        animation-duration: 10ms
        -webkit-box-align: center
        align-items: center
        display: flex
        font-size: 14px
        height: unset
        line-height: 1.6
        padding: 6px 12px
        border-radius: 6px
        outline:none
        transition: rgb(64, 68, 79) 300ms cubic-bezier(0.4, 0, 1, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 1, 1) 0ms
        &:hover,&:active
            box-shadow: rgb(9 30 66 / 8%) 0px 1px 2px 0px
    .form-input-password-container
        position: relative
        display: flex
        // padding-left: 0
        .input-password-field
            width: calc(100% - 30px)
            overflow: hidden
            border: none
            // height: 30px
            background-color: transparent
            outline:none
        button
            width: 30px
            height: 100%
            justify-content: center

    // .input-field-textarea
    //     border: 1px solid #cdcdcd
    //     border-radius: 5px
    //     padding: 5px
    // .input-field-selectbox
    //     border: 1px solid #cdcdcd
    //     height: 35px
    //     border-radius: 5px
    //     padding: 0 8px
    //     outline: none
    .input-field-date
        position: relative
        .input-field-date-box-container
            width: 100%
            height: 35px
            border-radius: 5px
            border: 1px solid #cdcdcd
            padding-left: 5px
            display: flex
            align-items: center
            .input-field-date-box
                width: calc(100% - 35px)
                height: 35px
                border:none
                background-color: transparent
                outline:none
                display: flex
                align-items: center
                font-size: 14px
        .common-calendar-container
            position: absolute
            top: 40px
            left: 0
            z-index: 5
            width: fit-content
            // width: 100%
    .input-field-radio-group
        display: flex
        height: 35px
        align-items: center
    .input-field-date-range-container
        position: relative
        .input-field-date-range-box-container
            display: flex
            height: 45px
            align-items: center
            border: 1px solid #cdcdcd
            background-color: #fafafa
            padding: 0 12px
    .input-select-field-container
        height: 35px
        border: 1px solid #cdcdcd
        border-radius: 5px
        display: flex
        .input-select-field-box
            height: 35px
            border:none
            background-color: transparent
            border-top-left-radius: 5px
            border-bottom-left-radius: 5px
            text-align: right
            padding-right: 5px
            font-family: 'Inter', sans-serif
        .select-unit-selectbox
            width: 70px
            height: 100%
            border-top-right-radius: 5px
            border-bottom-right-radius: 5px
            border : none
            border-left: 1px solid #cdcdcd
    .date-range-input-box-container
        height: 35px
        border: 1px solid #cdcdcd
        border-radius: 5px
        display: flex
        align-items: center
            


.create-operator-header-container
    height: 2.5em
    background-color: #f5f5f5
    width:100%
    box-shadow: -2px 2px 10px #cdcdcd
    padding: 0 15px
    display: flex
    align-items: center

.form-action-row-container
    width: 250px
    display: flex
    justify-content: space-between
    height: 35px
    align-items: center
    margin: 0 auto
    button
        min-width: 100px
        width: fit-content
        padding: 0 10px
        height: 33px
        border-radius: 5px
        justify-content: center
    .submit-btn
        background-color: var(--background-color)
        border: 1px solid var(--background-color)
        color: white
        transition: all 0.3s ease-in-out
        &:hover
            background-color: transparent
            color: var(--background-color)
        &:disabled
            color: #cdcdcd
            background-color: transparent
            border:1px solid #cdcdcd
    .cancel-btn
        background-color: transparent
        border: 1px solid #cdcdcd

.popup-overlay-container
    width: 100vw
    height: 100vh
    position: fixed
    background-color: rgba(0,0,0,0.4)
    top: 0
    left:0
    z-index: 99
    position: fixed
    overflow: hidden
    .popup-loading-container
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        min-width: 200px
        width: fit-content
        height: 50px
        border-radius: 5px
        background-color: white
        display: flex
        justify-content: center
        align-items: center
        padding: 0 15px
        font-size: 14px

.loading-spinner
    width: 16px
    height: 16px
    border: 3px solid transparent
    border-top: 3px solid #696969
    border-radius:50%
    animation: spinner 0.5s infinite ease-in-out
    margin-right: 5px

@keyframes spinner 
    from 
        transform: rotate(0deg)
    to
        transform: rotate(360deg)

.ada-operator-list-page
    // width: 95%
    // height: calc(100% - 5em)
    height: 100%
    // margin: 1em auto 0
    .ada-operator-list-header
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
    .ada-operator-list-container
        width:95%
        height: calc(100% - 6em)
        margin: 2em auto 0

.main-loading-container
    width: 95%
    height: calc(100% - 5em)
    position: relative
    .loading-container
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        display: flex
        align-items: center
        column-gap: 8px

.artist_profile
    width: 40px
    height: 40px
    border-radius: 50%
    transition: all 0.5s ease-in-out
    box-shadow: 0px 1px 7px #cdcdcd
    position: absolute
    &:hover
        z-index: 1500
        transform: scale(2.5)
        margin-bottom: 40px

.dummy_profile
    width: 40px
    height: 40px
    border-radius: 50%

.first--artist_profile
    width: 40px
    height: 40px
    border-radius: 50%
    transition: all 0.5s ease-in-out
    box-shadow: 0px 1px 7px #cdcdcd
    position: absolute
    &:hover
        z-index: 1500
        transform: scale(2.5)
        margin-top: 40px

.create-operator-container
    height: 100%
    width: 100%
    overflow: hidden
    .create-operator-form-container
        height: calc(100% - 3em)
        display: flex
        align-items: center
        justify-content: center
        .create-operator-form
            width: fit-content
            height: fit-content
            margin: 0 auto
            display: flex
            flex-direction: column
            row-gap: 5px


.list-header-bar-container
    // width: 100%
    height: 2.5em
    padding: 0 2.2%
    display: flex
    align-items: center
    justify-content: space-between
    background-color: #f5f7f9
    box-shadow: 0 2px 10px #cdcdcd


.confirmation-popup-container
    width: 380px
    height: 280px
    border-radius: 5px
    background-color: white
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    .close-btn
        width: 33px
        height: 33px
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        top: 15px
        right: 15px
    .confirmation-popup-header
        height: 80px
        width: 100%
        display: flex
        flex-direction: column
        row-gap: 5px
        justify-content: center
        align-items: center
        border-bottom: 1px solid #f5f5f5
        padding: 15px 0
        color: #F52D7E
        h4
            margin: 0
            color: #F52D7E
    .confirmation-popup-body
        height: calc(100% - 150px)
        background-color: #f8f8f8
        padding: 40px 0
        border-bottom-right-radius: 5px
        border-bottom-left-radius: 5px
        display: flex
        flex-direction: column
        justify-content: space-between
        p
            width: 80%
            margin: 0 auto
            white-space: pre-wrap
            word-break: break-word
            color: #696969
    .confirm-btn
        width: fit-content
        padding: 10px 20px
        color: white
        justify-content: center
        border-radius: 8px
        &.delete
            background-color: #F52D7E
            border: 1px solid #D3447F
    .cancel-confirm-btn  
        color: #828282
        border-radius: 8px
        background-color: white
        padding: 10px 20px
        border: 1px solid #e3e3e3


.artist-profile-container
    width: 140px
    height: 130px
    display: flex
    justify-content: center
    align-items: center
    background-color: #f5f5f5
    border: 1px solid #cdcdcd
    border-radius: 5px
    position: relative
    .upload-profile
        border:none
        width: 100%
        height: 100%
        background-color: transparent
        outline: none
        position: absolute
        opacity: 0
        // display: none
    .remove-profile
        width: 33px
        height: 33px
        position: absolute
        right: -15px
        top: -15px
        display: flex
        justify-content: center
        align-items: center
        background-color: #fafafa
        border-radius: 50%
        box-shadow: 1px 2px 3px #cdcdcd
        z-index: 2
        cursor: pointer

    
.upload-profile-btn
    width: 140px
    height: 35px
    border: 1px solid var(--background-color)
    border-radius: 5px
    background-color: transparent
    color: var(--background-color)
    justify-content: center
    margin-top: 15px
    position: relative

.upload-profile-btn-input
    width: 100%
    height: 100%
    opacity: 0
    position: absolute

.image-upload-error-message
    color: brown
    white-space: pre-wrap
    word-break: break-word
    font-size: 12px
    width: 140px

.date-range-input-box
    border: 1px solid #cdcdcd
    border-radius: 5px
    height: 35px
    padding: 0 8px
    font-family: 'Inter', sans-serif
    font-size: 14px

.drop-box-container
    width: 99%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 5px
    height: 150px
    border: 2px dashed #cdcdcd
    row-gap: 10px

.choose-img
    width: 100px
    height: 33px
    border-radius: 5px
    background-color: var(--background-color)
    color: white
    justify-content: center
    font-family: 'Inter', sans-serif

.preview-img-list
    width: 99%
    display: flex
    flex-wrap: wrap
    gap: 25px 10px
    margin-top: 5px
    // justify-content: space-between
    .img-container
        position: relative
        height: 100px
        width: 150px
        border-radius: 5px
        background-color: #696969
        display: grid
        place-items: center
        // button
        //     position: absolute
        //     top: 5px
        //     right: 5px
        //     width: 25px
        //     height: 25px
        //     border-radius: 50%
        //     background-color: #f5f5f5
        //     color: #cdcdcd
        //     justify-content: center

.upload-media-info-list-container
    .upload-media-info-list-tab
        height: 45px
        border-bottom: 1px solid #cdcdcd
        width: 99%
        display: flex
        align-items: center
        column-gap: 15px
        button
            border:none
            background-color: transparent
            height: 100%
            border-bottom: 2px solid transparent
        .active-media-type
            border-bottom: 2px solid var(--primary-color)
            color: var(--primary-color)

.main-empty-page-container
    width: 100%
    height: calc(100% - 5em)
    display: flex
    flex-direction: column
    row-gap: 15px
    justify-content: center
    align-items: center


.popup-header
    width: 100%
    height: 9vh
    background-color: var(--background-color)
    border-top-left-radius: 5px
    border-top-right-radius: 5px
    display: flex
    align-items: center
    position: relative
    box-shadow: 0px 3px 5px #cdcdcd
    color: white
    label
        width: 100%
        text-align: center
    button
        width: 33px
        height: 33px
        justify-content: center
        border-radius: 5px
        position: absolute
        right: 10px
        color: white
        &:hover
            background-color: rgba(255, 255, 255, 0.08)

.edit-image-box-container
    height: fit-content
    border-radius: 5px
    position: relative
    background-color: #f5f5f5
    padding: 8px
    width: fit-content
    border: 1px solid #eaeaea
    button
        width: 25px
        height: 25px
        justify-content: center
        background-color: #E34F5C
        color: white
        border-radius: 50%
        position: absolute
        right: 5px
    .edit-image-box
        width: 200px
        height: 150px
        img
            width: 100%
    input
        height: 35px
        border: 1px solid #cdcdcd
        border-radius: 5px
        width: calc(100% - 8px)
        font-size: 12px
        padding-left: 8px
        font-family: 'Inter', sans-serif
        &:active
            border: 1px solid #cdcdcd


.delete-img-btn
    width: 25px
    height: 25px
    justify-content: center
    background-color: #E34F5C
    color: white
    border-radius: 50%
    position: absolute
    right: 5px
    top: 5px



.full-page-container
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    background-color: #10192B
    z-index: 99
    .page-not-found-section
        width: 58%
        height: fit-content
        display: flex
        justify-content: space-between
        .page-not-found-section-left-side
            width: 45%
            height: 100%
            margin: auto 0
            h1
                span
                    &:first-child
                        margin-right: 10px
                        color: #F7C63B
                    &:last-child
                        color: #DEE9FA
            p
                color: #dee9fa
                font-size: 12px
            button
                width: 150px
                height: 33px
                border-radius: 5px
                background-color: #FFBD14
                // color: #7E5B00
                color: #000
                justify-content: center
        .page-not-found-section-right-side
            width: 380px
            border: none
            img
                width: 350px
                border: none


.react-loading
    position: relative
    width: 1.5em
    height: 1.5em
    border-radius: 100%
    animation: reactLoad1 3s infinite linear
    &::before, &::after
        // background-color: var(--background-color)
        content: ''
        border-radius: 100%
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
    &::before
        width: 1.3em
        height: 2em
        animation: reactLoad2 .8s linear infinite
    &::after
        width: 2em
        height: 1.3em
        animation: reactLoad2 1.2s linear infinite


@keyframes reactLoad1
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
        
@keyframes reactLoad2
    0%
        box-shadow: 0.06em -0.06em 0 0.04em var(--background-color)
    25%
        box-shadow: 0.06em -0.06em 0 0.04em  var(--background-color)
    50%
        box-shadow: -0.06em 0.06em 0 0.04em var(--background-color)
    75%
        box-shadow: -0.06em -0.06em 0 0.04em var(--background-color)
    100%
        box-shadow: 0.06em -0.06em 0 0.04em var(--background-color)

.bar-loading-container
    position: relative
    animation: barLoad 1s ease-in-out infinite
    animation-delay: 0.4s
    width: .25em
    height: .5em
    margin: 0 .5em
    background-color: var(--background-color)
    &::after, &::before
        content: ''
        position: absolute
        width: inherit
        height: inherit
        background: var(--background-color)
        animation: inherit
    &::before
        right: .5em
        animation-delay: 1s
    &::after
        left: .5em
        animation-delay: 0.6s


@keyframes barLoad 
    0%, 100% 
        box-shadow: 0 0 0 var(--background-color), 0 0 0 var(--background-color)
    50% 
        box-shadow: 0 -.25em 0 var(--background-color), 0 .25em 0 var(--background-color)

.circle-dot-loading-container
    border: 1px solid var(--background-color)
    width: 1.5em
    height: 1.5em
    border-radius: 50%
    position: relative
    animation: 1s spinner linear infinite
    &::before
        width: 0
        height: 0
        content: ''
        display: block
        position: absolute
        top: -.2em
        left: 50%
        border: .2em solid var(--background-color)
        border-radius: 50%

.error-message
        color: brown
        font-size: 14px
        position: absolute
        top: 38px
        left: 3px
        &.vertical-layout
            position: relative
            inset: initial


.label-container
    width: fit-content
    height: 30px
    border-radius: 25px
    max-width: 230px
    overflow:hidden
    text-overflow: ellipsis
    padding: 0 8px
    display: flex
    align-items: center
    text-transform: capitalize
    font-size: 12px
    &.active
        background-color: rgba(122, 224, 246, 0.2)
        color: rgba(0, 159, 159, 1)

    &.inactive
        background-color: rgba(246, 122, 122, 0.2)
        color: rgba(237, 96, 96, 1)

// .square-loading-container
//     width: 0.5em
//     height: 0.5em
//     box-shadow: -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color)
//     animation: squareLoad 6s infinite

// @keyframes squareLoad
//     0%
//         box-shadow: -1em -1em 0 0.5em var(--background-color),-1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color)
//     8.33%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), 1em -1em 0 5em var(--background-color), 1em -1em 0 5em var(--background-color), 1em -1em 0 5em var(--background-color)
//     16.66%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), 1em -1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color)
//     24.99%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), 1em -1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color)
//     33.32%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), 1em -1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), -1em -1em 0 0.5em var(--background-color)
//     41.65%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), 1em -1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), 1em -1em 0 5em var(--background-color)
//     49.98%
//         box-shadow: 1em 1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color)
//     58.31%
//         box-shadow: -1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color)
//     66.64%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color), 1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color)
//     74.97%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), 1em -1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color)
//     83.3%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), 1em 1em 0 5em var(--background-color), 1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color)
//     91.63%
//         box-shadow: -1em -1em 0 0.5em var(--background-color), -1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color), -1em 1em 0 5em var(--background-color)
// 100%
//     box-shadow: -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color), -1em -1em 0 0.5em var(--background-color)

        