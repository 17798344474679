@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: 'Inter', sans-serif;
}

:root{
  --primary-color: #e6593d;
  --secondary-color: #CFDBD5;
  --green-color: #6a8f7c;
  --error-color: #f44336;
  /* --background-color: #0039C5; */
  --background-color: rgb(105, 65, 198);
  --nav-background-color: rgb(30, 33, 42);
  --nav-text-color: rgb(52, 64, 84);
  --active-nav-text-color:rgb(182, 146, 246);
  --background-text-color: #6b98ff;
  --active-text-color: #B4DFFF;
  --hover-background-color: #0E43CA;

}

button{
  border: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.login-alert-message-container{
  position: fixed;
  bottom: 5vh;
  width: 350px;
  left: 50%;
  transform: translateX(-50%)
}

button{
  border:none;
  background-color: transparent;
  display: flex;
  align-items: center;
  outline: none;
  font-family: 'Inter', sans-serif;
}

input, textarea, select{
  font-family: 'Inter', sans-serif;
}

input::placeholder{
  font-family: 'Inter', sans-serif;
}


.edit-button{
  width: 100px;
  height: 33px;
  color: dodgerblue;
}

.delete-button{
  width: 100px;
  height: 33px;
  color: brown;
}

.create-button{
  width: 100px;
  height: 33px;
  color: dodgerblue;
  display:flex;
  align-items: center;
  background-color: rgb(161 130 233);
  justify-content: center;
  color: white;
  fill: white;
  border-radius: 5px;
  box-shadow: -2px 1px 10px #cdcdcd;
  transition: all .2s ease-in-out;
}

.create-button:disabled, .edit-button:disabled, .delete-button:disabled{
  color: #cdcdcd
}

.action-cell-container{
  width: 100%;
  justify-content: space-between;
  height: 100%;
  display: flex;
  align-items: center;
}

.back-btn{
  width: 45px;
  height: 45px;
  background-color: transparent;
  justify-content: center;
}

input{
  border:none;
  outline:none;
  font-family: 'Poppins', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 25px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eaeaea;
}