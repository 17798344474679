.create-product-page-section
    width: 85%
    height: 100%
    margin: 0 auto
    .create-product-page-stepper-container
        height: 3em 
        display: flex
        align-items: center
        width: 60%
        margin: 0 auto
    .create-product-page-body-container
        height: calc(100vh - 14em)

.create-product-form
    width: 100%
    margin: 25px auto 0
    display: flex
    justify-content: space-between
    &.upload-media
        flex-direction : column
    .create-product-form-left-side
        width: 40%   
        margin-left: 5px
        .create-product-form-row
            width: 100%
            // display: flex
    .create-product-form-right-side
        width: 40%
        margin-right: 15px

.choose-price-input-box
    height: 35px
    border: 1px solid #cdcdcd
    border-top-right-radius: 5px
    border-bottom-right-radius: 5px
    background-color: #fafafa
    outline:none
    padding-right: 15px
    width: 155px
    text-align: right
    font-family: 'Inter', sans-serif

.input-field-currency-selectbox
    border: 1px solid #cdcdcd
    height: 39px
    border-top-left-radius: 5px
    border-bottom-left-radius: 5px
    padding: 0 8px
    outline: none
    border-right: 0