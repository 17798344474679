.ada-admin-header-bar
    display: flex
    height: 3em
    // width: 100%
    background-color: var(--background-color)
    padding: 0 25px
    justify-content: space-between
    .ada-admin-logo
        height: 100%
        display: flex
        align-items: center
        cursor: pointer
        img
            width: 5em
            height: 3em
            cursor: pointer
    .ada-admin-header-admin-profile
        min-width: 120px
        height: 100%
        display: flex
        align-items: center
        position: relative
        cursor: pointer
        .ada-admin-header-admin-profile-name
            display: flex
            align-items: center
            column-gap: 5px
            color: white
        .ada-admin-header-profile-dropdown
            position: absolute
            width: 100%
            height: fit-content
            border-radius: 8px
            background-color: #0f0d18
            top: 3.1em
            z-index: 10
            button
                background-color: transparent
                height: 40px
                color: white
                width: 100%
                display: flex
                align-items: center
                border-radius: 8px
                &:hover
                    background-color: #696969
