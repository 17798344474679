.create-banner-form
    margin: 1.5em 0 8px 2.5em
    display: flex
    column-gap: 25px
    height: calc(100vh - 200px)
    .create-banner-form-left-side
        width: 35%
    .create-banner-form-right-side
        width: 40%
        margin: 0 auto 0 10px
        display: flex
        flex-direction: column
        .create-banner-form-row
            

.banner-image-list
    width: 100%
    .banner-image-list-container
        width: 100%
        background-color: #f5f5f5
        position: relative
        margin-top: 10px
        height: 250px
        button  
            position: absolute
            width: 100px
            height: 30px
            border-radius: 25px
            background-color: white
            top: 5px
            left: 10px
            justify-content: center
            box-shadow: 2px 2px 5px #cdcdcd
            color: #333
    .banner-image-list-grid
        display: grid
        place-items: center
        width: 100%
        height: 250px
        overflow: hidden
        &.three
            grid-template-rows: 120px 120px
            gap: 8px
        &.four
            grid-template-rows: 120px 120px
            grid-template-columns: 48% 48%
            justify-items: center
            gap: 8px
        &.five
            grid-template-rows: 120px 120px
            grid-template-columns: 48% 48%
            gap: 8px
        .banner-image-container
            // width: 100%
            height: 100%
            img
                width: 100%
                height: 100%
    // .banner-image-list-grid
    //     display: flex
    //     flex-direction: column
    //     .banner-image-first-row
    //         width: 100%
    //         display: flex
    //         justify-content: center
    //         img
    //             width: fit-content
    //             height: 120px
    //     .banner-image-second-row
            width: 100%
            display: flex
            height: 120px
            .banner-image-container
                width: 100%
                height: 100%
                img
                    width: fit-content
                    height: 100%

.edit-banner-image-list-popup
    width: 70vw
    height: 76vh
    border-radius: 5px
    background-color: white
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    .edit-banner-image-list
        padding: 15px
        // height: calc(100% - 12vh)
        display: flex
        flex-wrap: wrap
        gap: 15px


