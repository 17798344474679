.profile-editor-popup
    width: 55vw
    height: 75vh
    border-radius: 5px
    background-color: white
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    .profile-editor-image-container
        width: 90%
        margin: 0 auto
        background-color: rgba(0, 0, 0, 0.4)
        display: flex
        justify-content: center
        align-items: center
        padding: 20px
        margin-top: 10px
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        height: 230px
    .profile-editor-action-container,.profile-editor-popup-button-row 
        width: 90%
        padding: 8px 20px
        display: flex
        justify-content: space-between
        height: 5.5vh
        align-items: center
        margin: 0 auto
        background-color: #fafafa
        box-shadow: -1px 2px 8px #efefef
        border-bottom-left-radius: 5px
        border-bottom-right-radius: 5px
        button
            width: 50px
            height: 33px
            border-radius: 3px
            justify-content: center
            color: #4b4a4a
        .profile-editor-action-left-side
            display: flex
        .profile-editor-action-right-side
            width: 200px
            display: flex
            height: 100%
            align-items: center
            button
                color: #1976D2
    .profile-editor-popup-button-row 
        box-shadow: none
        margin-top: 15px
        background-color: transparent
        width: 95%
        padding: 20px 0 
        button
            width: fit-content
            padding: 0 25px
            height: 33px
            border-radius: 3px
            justify-content: center
        .replace-image-btn
            color: var(--background-color)
        .profile-editor-popup-bottom-right-side
            display: flex
        .dismiss-profile-editor

        .save-profile-editor
            background-color: var(--background-color)
            color: white
            