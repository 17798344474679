.dashboard-info-card-container
    width: 32%
    // height: 250px
    background-color: white
    color: rgb(16, 24, 40)
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    border-radius: 6px
    box-shadow: rgb(31 41 55 / 8%) 0px 1px 2px
    // overflow: hidden
    border: 1px solid rgb(234, 236, 240)
    height: 100%
    .dashoboard-info-card--first-part
        align-items: center
        display: flex
        padding: 16px
        column-gap: 8px
        .dashboard-info-card-icon
            display: flex
            align-items: center
            justify-content: center
            flex-shrink: 0
            line-height: 1
            border-radius: 50%
            overflow: hidden
            user-select: none
            font-size: 14px
            font-weight: 600
            letter-spacing: 0px
            // color: rgb(52, 64, 84)
            color: white
            fill: white
            background-color: rgb(122, 90, 248)
            height: 56px
            width: 56px
        .dashboard-info-card-info
            span
                margin: 0px
                font-size: 12px
                font-weight: 600
                letter-spacing: 1px
                line-height: 2.46
                font-family: Inter, sans-serif
                text-transform: uppercase
                color: rgb(52, 64, 84)
            h6
                margin: 0px
                font-size: 16px
                font-weight: 600
                line-height: 1.5
                font-family: Inter, sans-serif
                color: rgb(16, 24, 40)
    .dashboard-info-card--second-part
        display: flex
        -webkit-box-align: center
        align-items: center
        background-color: rgb(249, 250, 251)
        padding: 8px 24px
        button
            color: var(--background-color)
            background-color: transparent
            width: 150px
            height: 33px
            font-family: 'Inter', sans-serif
        

.dashboard-card-container
    background-color: white
    color: rgb(16, 24, 40)
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    border-radius: 6px
    box-shadow: rgb(31 41 55 / 8%) 0px 1px 2px
    overflow: hidden
    border: 1px solid rgb(234, 236, 240)
    &.overview-card
        width: 49%
    &.product-overview-card
        width: 100%
        height: 535px
    .dashboard-card-header
        display: flex
        align-items: center
        padding: 16px 24px
        border-bottom: 1px solid rgb(234, 236, 240)
        justify-content: space-between
        .dashboard-card-header-left-side
            display: flex
            button
                color: #0E43CA
            h4
                margin: 0
    .dashboard-card-body
        padding: 24px


.ada-dashboard-info-card-list
    width: calc(100% - 30px)
    margin: 24px 6px 0 24px
    display: flex
    flex-wrap: nowrap
    justify-content: space-between

.artist-overview-chart
    width: 180px
    margin: 0 auto 10px


.ada-artist-overview-sub-header
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    height: 35px
    border-bottom: 1px solid #f5f5f5
    h4
        margin: 0

.ada-artist-type-list-container
    .artist-type-info-container
        width: 100%
        height: 38px
        display: flex
        justify-content: space-between
        align-items: center
        border-bottom:1px solid #f5f5f5
        .artist-type-info-left-side
            width: calc(100% - 50px)
            display: flex
            align-items: center
            column-gap: 8px
            font-size: 14px
            span
                width: 8px
                height: 8px
                border-radius: 50%
        .artist-type-count
            width: 50px
            text-align: center
            font-size: 14px

.category-info-container
    height: 70px
    display: flex
    column-gap: 15px
    align-items: center
    border-bottom: 1px solid #f5f5f5
    img
        width: 45px
        height: 45px
        border-radius: 50%


.dashboard-select-box-container
    display: inline-flex
    align-items: center
    justify-content: space-between
    position: relative
    box-sizing: border-box
    background-color: transparent
    outline: 0px
    border: 0px
    margin: 0px
    cursor: pointer
    user-select: none
    vertical-align: middle
    appearance: none
    text-transform: none
    font-family: Inter, sans-serif
    // line-height: 1.75
    min-width: 100px
    height: 100%
    width: fit-content
    padding: 4px 5px
    border-radius: 6px
    transition: var(--background-color) 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    color: rgb(122, 90, 248)
    font-weight: 600
    font-size: 13px
    .dashboard-chosen-value
        width: 100%
        justify-content: space-between
    .dashboard-select-list
        border: 1px solid rgb(208, 213, 221)
        background-color: rgb(255, 255, 255)
        color: rgb(16, 24, 40)
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
        border-radius: 6px
        box-shadow: rgb(31 41 55 / 12%) 0px 8px 20px -2px
        position: absolute
        overflow: hidden auto
        width: 120px
        // max-width: calc(100% - 32px)
        // max-height: calc(100% - 32px)
        outline: 0px
        padding: 8px 0
        top: 30px
        right: 0
        button
            background-color: transparent
            outline: 0px
            border: 0px
            margin: 0px
            border-radius: 0px
            cursor: pointer
            user-select: none
            vertical-align: middle
            font-family: Inter, sans-serif
            display: flex
            justify-content: flex-start
            align-items: center
            min-height: 48px
            padding: 6px 16px
            white-space: nowrap
            font-size: 14px
            font-weight: 400
            letter-spacing: 0.15px
            line-height: 1.43
            width: 100%
            &:hover
                background-color: #f5f5f5