.ada-admin-navigation-bar
    width: 48px
    // background-color: var(--background-color)
    background-color: rgb(249, 250, 251)
    height: calc(100vh - 3.1em)
    overflow: hidden
    // padding: 0 5px
    // position: fixed
    // top: 0
    bottom: 0
    left:0
    z-index: 10
    transition: width 0.2s linear
    border:1px solid rgb(234, 236, 240)
    .ada-admin-nav-header
        display: flex
        justify-content: space-between
        height: 3em
        align-items: center
        width: 90%
        margin:0 auto
        .ada-admin-logo
            height: 3em
            display: flex
            align-items: center
            color: white
            h3
                color: white
                text-transform: uppercase
            img
                width: 4em
                height: 3em
        .ada-nav-menu-icon
            color: white
            background-color: transparent
    .ada-admin-nav-list
        width: 90%
        margin: 0 auto
        ul
            list-style-type: none
            padding: 0
            .ada-admin-menu-item
                min-width: 23px
                height: 40px
                overflow: hidden
                margin-bottom: 15px
                white-space: nowrap
                border-radius: 5px
                display: flex
                justify-content: center
                align-items: center
                color: var(--background-text-color)
                position: relative
                .admin-nav-item
                    color: var(--background-text-color)
                    text-decoration: none
                    display: flex
                    height: 100%
                    .navigation-menu
                        font-size: 14px
                        color: var(--nav-text-color)
                        fill: var(--nav-text-color)
                        display: flex
                        align-items: center
                        &.active
                            color: var(--active-nav-text-color)
                            fill: var(--active-nav-text-color)
                        &:hover
                            color: var(--active-nav-text-color)
                            fill: var(--active-nav-text-color)
                    .tooltip-menu
                        position:relative
                        &::before
                            position: absolute
                            top: 47%
                            transform: translateY(-50%)
                            left: 100%
                            margin-left: 10px
                            content: attr(data-text)
                            width: 200px
                            padding:10px
                            border-radius:10px
                            background:#000
                            color: #fff
                            text-align:center
                    &.expand-item
                        width: 200px
                        padding-left: 25px
                    &.active
                        color: var(--active-nav-text-color)
                &:hover
                    box-shadow: rgb(182 146 246 / 25%) 0px 0px 0px 0.2rem
                .menu-active-bar
                    position: absolute
                    right: 0
                    width: 2px
                    height: 40px
                    background-color: var(--active-nav-text-color)
